import { FetchAccessToken } from 'apollo-link-token-refresh';
import dayjs from 'dayjs';
import jwtDecode, { JwtPayload } from 'jwt-decode';

export const setRefreshToken = (newToken: string) => {
  if (typeof localStorage === 'undefined') {
    return null;
  }
  return localStorage.setItem('refresh-token', newToken);
};

export const getRefreshToken = () => {
  if (typeof localStorage === 'undefined') {
    return null;
  }
  return localStorage.getItem('refresh-token');
};

export const setAccessToken = (newToken: string) => {
  if (typeof localStorage === 'undefined') {
    return null;
  }
  return localStorage.setItem('token', newToken);
};

export const getAccessToken = () => {
  if (typeof localStorage === 'undefined') {
    return null;
  }
  return localStorage.getItem('token');
};

export const isTokenExpired = () => {
  const accessToken = getAccessToken();
  try {
    const accessTokenDecrypted = jwtDecode<JwtPayload>(accessToken as string);
    const { exp } = accessTokenDecrypted as any;
    if (typeof exp === 'undefined') {
      return false;
    }
    const expObj = dayjs.unix(exp);
    return dayjs().isAfter(expObj);
  } catch (error) {
    return false;
  }
};

export const fetchAccessToken: FetchAccessToken = async () => {
  // return axios.get("http://localhost:4000/auth/refresh", {
  //   responseType: "json",
  //   withCredentials: true,
  //   mode: "cors",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //     authorization: `Bearer ${getAccessToken()}`,
  //     Origin: "http://localhost:3000",
  //     // "refresh-token": getRefreshToken(),
  //   },
  // });
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_HOST}/auth/refresh`,
    {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        // "Content-Type": "application/json",
        Accept: 'application/json',
        authorization: `Bearer ${getAccessToken()}`,
        Origin: process.env.NEXT_PUBLIC_APP_URL,
        'refresh-token': getRefreshToken(),
      } as HeadersInit,
    },
  );
  return response.json();
};

export const isTokenValidOrUndefined = async () => {
  return !isTokenExpired() || typeof getAccessToken() !== 'string';
};

export const handleFetch = (tokens: any) => {
  const { accessToken, refreshToken } = tokens;
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
};

export const handleResponse =
  (_operation: any, _accessTokenField: any) => (response: any) => {
    if (!response) return { accessToken: null, refreshToken: null };
    return { tokens: response };
  };
