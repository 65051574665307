/* eslint-disable @typescript-eslint/no-var-requires */
// eslint-disable-next-line @typescript-eslint/no-var-requires
const path = require('path');
const HttpBackend = require('i18next-http-backend/cjs');
const ChainedBackend = require('i18next-chained-backend').default;
const LocalStorageBackend = require('i18next-localstorage-backend').default;

const isBrowser = typeof window !== 'undefined';

module.exports = {
  backend: {
    backendOptions: [
      { expirationTime: 60 * 60 * 1000 },
      {
        loadPath: '/static/locales/{{lng}}/{{ns}}.json',
      },
    ],
    backends:
      typeof window !== 'undefined' ? [LocalStorageBackend, HttpBackend] : [],
  },
  i18n: {
    defaultLocale: 'en',
    locales: ['en', 'ja'],
  },
  interpolation: {
    escapeValue: false,
  },
  defaultNS: 'common',
  fallbackLng: {
    default: ['en'],
  },
  localeExtension: 'json',
  localePath: path.resolve('./public/static/locales'),
  serializeConfig: false,
  use: typeof window !== 'undefined' ? [ChainedBackend] : [],
  partialBundledLanguages: isBrowser && true,
};
